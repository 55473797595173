<template>
	<v-row v-if="question" :class="{ 'd-none': question.always_hidden }">
		<v-col>
			<div class="mw-question-wrapper" v-if="question">
				<question-title v-if="question.title" :id="id"></question-title>
				
				
				<div class="mw-question-body">
					<v-component
						:is="componentName"
						:answer-options-order="answerOptionsOrder"
						:subquestions="subquestions"
						:value="value"
						
						@update="(v) => $emit('input', v)"
					>
					</v-component>
				</div>
				<div class="mw-question-footer"></div>
			</div>
		</v-col>
	</v-row>
</template>

<style lang="less">
.mw-question-description {
	font-size: 0.8em;
}
</style>

<script type="text/javascript">
export default {
	name: "Question",
	props: {
		id: {},
		data: {},
		value: {},
		disabled: { type: Boolean },
	},
	provide() {
		return {
			question: this.question,
			disabled: this.disabled,
			multiple: this.question.multiple,
			answerOptions: this.answerOptions,
		};
	},
	components: {
		QuestionTitle: () => import("@c/survey/render/QuestionTitle"),
		Comment: () => import("@c/survey/questionTypes/Comment"),
		Html: () => import("@c/survey/questionTypes/Html"),
		RadioTiles: () => import("@c/survey/questionTypes/RadioTiles"),
		Checkboxes: () => import("@c/survey/questionTypes/Checkboxes"),
		Header: () => import("@c/survey/questionTypes/Header"),
		Input: () => import("@c/survey/questionTypes/Input"),
		Matrix: () => import("@c/survey/questionTypes/Matrix"),
	},
	watch: {
		profileFieldValue: {
			immediate: true,
			handler(v){
				if( v && v.value ){
					this.$emit("input", v.value )
				}
			}
		}
	},
	computed: {
		question() {
			return this.$store.state.questions.data[this.id];
		},
		profileFieldValue(){
			if( this.question.profile_field ){
				return this.$store.getters.getByKey("profileFieldValues", "profile_field", this.question.profile_field )[0];
			}
			return null;
		},
		answerOptions() {
			return this.question.answer_options;
		},
		answerOptionsOrder() {
			const self = this;
			let answers = {};
			if (self.answerOptions) {
				answers = self.answerOptions;
			}

			let order = this.mwutils.getPositions(answers);

			order = order.filter( o => {
				let answer = self.answerOptions[o];
				return self.mwsurveyutils.isVisible(answer, self.data ) 
			})

			return order;
		},
		subquestions(){
			const self = this;
			let subquestions = [];
			if( self.question.subquestions){
				subquestions = Object.values(self.question.subquestions)
			}
			return subquestions.filter( q =>  self.mwsurveyutils.isVisible(q, self.data )  )
		},
		componentName() {
			let types = {
				tiles: "RadioTiles",
				comment: "Comment",
				checkboxes: "Checkboxes",
				html: "Html",
				input: "Input",
				matrix: "Matrix",
				header: "Header",
			};

			return types[this.question.type];
		},
	},
};
//
</script>
"
