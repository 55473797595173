<template>
    <v-alert type="success" text v-if="haveProfileData" class="mt-2">
        We have found some answers you previously provided. Where possible, we have prefilled these answers for you.
    </v-alert>
</template>

<script>
export default {
    name: "PrefillWarning", 
    computed: {
        haveProfileData(){
            return Object.keys(this.$store.state.profileFieldValues.data).length > 0
        }
    }
}
</script>