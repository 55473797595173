<template>
	<v-sheet color="transparent">
		<v-window v-model="window">
			<v-window-item value="intro">
				<render-content
					:id="survey.introduction"
					class="mb-5"
				></render-content>
				<identify-participant
					v-if="showIdentity"
					v-model="participant"
				></identify-participant>
			</v-window-item>
			<v-window-item
				v-for="question in visibleQuestions"
				:key="question"
				:value="question"
			>
				<questions
					:ids="[question]"
					v-model="data"
					@error="error = true"
				></questions>
			</v-window-item>
		</v-window>
		<mobile-controls
			v-model="window"
			:data="data"
			:context="windowOrder"
			v-if="!disable"
			:missing-contact-details="missingContactDetails"
			@submit="$emit('submit', data, participant)"
		></mobile-controls>
	</v-sheet>
</template>

<script>
import Questions from "@c/survey/render/Questions.vue";
import MobileControls from "@c/survey/render/MobileControls.vue";
import IdentifyParticipant from "@c/auth/IdentifyParticipant";
import RenderContent from "@c/ui/RenderContent.vue";
export default {
	name: "MobileSurvey",
	data: () => {
		return {
			error: false,
			data: {},
			window: 0,
			participant: false,
		};
	},
	props: {
		disable: {type: Boolean}
	},
	components: {
		RenderContent,
		Questions,
		IdentifyParticipant,
		MobileControls,
	},
	computed: {
		missingContactDetails(){
			if( this.showIdentity ){
				if( !this.participant.email && !this.participant.phone ){
					return true;
				}
			}
			return false;
		},
		windowOrder() {
			let order = ["intro"];
			order = order.concat(this.visibleQuestions);
			return order;
		},
		visibleQuestions() {
			return this.getVisibleQuestions(this.data);
		},
	},
	inject: [
		"isMobile",
		"survey",
		"showIdentity",
		"noQuestions",
		"response",
		"questions",
		"getVisibleQuestions",
		"isAnswered",
	],
	watch: {
		response(value) {
			if (value) {
				this.data = value.data;
			}
		},
	},
};
</script>
