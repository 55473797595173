<template>
	<v-card color="white" flat class="rounded-lg mt-4 mb-4">
		<v-card-title class="font-weight-light">Your details</v-card-title>
		<v-card-text>
			<v-form v-model="isValid" ref="form" v-if="!logged_in">
				<v-text-field
					v-model="email"
					label="Email"
					:rules="emailRules"
					@blur="checkAccount"
					autocomplete="email"
				></v-text-field>

				<v-text-field
					:prefix="prefix"
					append-icon="mdi-earth"
					@click:append="dialog = true"
					@input="(v) => (phoneInput = v)"
					:value="phoneBody"
					id="phone-input"
					autocomplete="phone"
					:rules="phoneRules"
					label="Mobile number"
					@blur="checkAccount"
				></v-text-field>
				<v-row>
					<v-col cols="12" md="6">
						<v-text-field
							v-model="first_name"
							label="First Name"
							autocomplete="given-name"
						></v-text-field>
					</v-col>
					<v-col cols="12" md="6">
						<v-text-field
							v-model="last_name"
							label="Last Name"
							autocomplete="family-name"
						></v-text-field>
					</v-col>
				</v-row>
				<v-alert v-if="uid" type="success" text>
					An account matching this {{ authType }} has been found.
					<p class="text-right mb-0 mt-2">
						<v-btn @click="$root.$emit('openLogin')" color="success" text
							>Login Now</v-btn
						>
					</p>
				</v-alert>
			</v-form>
			<div v-else>
				<p>{{ auth.name }}</p>
				<p v-if="auth.email">{{ auth.email }}</p>
				<p v-if="auth.phone">{{ auth.phone }}</p>
			</div>
		</v-card-text>
		<div>
			<v-spacer v-if="!isMobile"></v-spacer>
			<v-btn router-link to="/profile" text target="_blank" v-if="logged_in">
				Edit these details
			</v-btn>
			<v-btn text @click="$root.$emit('openLogout')" v-if="logged_in">
				Not You?
			</v-btn>
		</div>
		<v-dialog width="300" v-model="dialog">
			<v-card>
				<v-card-text>
					<v-autocomplete
						:items="prefixes"
						v-model="prefix"
					></v-autocomplete>
				</v-card-text>
				<v-card-actions>
					<v-spacer v-if="!isMobile"></v-spacer>
					<v-btn icon @click="dialog = false" color="green"
						><v-icon>mdi-chevron-right-circle</v-icon></v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>
<script>
import firebase from "firebase";
export default {
	name: "IdentifyParticipant",
	props: {
		value: { type: [Object, Boolean] },
	},
	data: () => {
		return {
			isValid: null,
			email: "",
			phoneInput: "",
			uid: null,
			first_name: "",
			last_name: "",
			name: "",
			dialog: false,
			prefix: "+44",
			authType: "",
			emailRules: [
				(v) => !!v || "E-mail is required",
				(v) => /^\S+@\S+\.\S+$/.test(v) || "E-mail must be valid",
			],
			phoneRules: [
				(v) => !!v || "Phone is required",
				(v) => /^\d{10}$/.test(v) || "Phone must be valid",
			],
		};
	},
	computed: {
		prefixes() {
			return this.$store.state.prefixes;
		},
		auth() {
			return this.$store.state.auth.data;
		},
		logged_in() {
			return this.$store.state.auth.logged_in;
		},
		isEmail() {
			return this.mwutils.validateEmail(this.email);
		},
		participant() {
			if (!this.logged_in) {
				return {
					id: this.uid,
					email: this.email,
					phone: this.phoneFull,
					name: this.name,
					first_name: this.first_name,
					last_name: this.last_name,
				};
			} else {
				return this.auth;
			}
		},
		phoneBody() {
			return this.mwutils.fixPhone(this.phoneInput, this.prefix);
		},
		phoneFull() {
			if (!this.phoneBody.length) {
				return "";
			}
			return `${this.prefix}${this.phoneBody}`;
		},
	},
	methods: {
		async checkUserByEmail() {
			const functions = firebase.app().functions("europe-west1");
			var check = functions.httpsCallable("isUserByEmail");
			return check(this.email).then((result) => {
				return result.data;
			});
		},
		async checkUserByPhone() {
			const functions = firebase.app().functions("europe-west1");
			var check = functions.httpsCallable("isUserByPhone");
			return check(this.phoneFull).then((result) => {
				return result.data;
			});
		},
		checkAccount() {
			if (this.isEmail) {
				this.checkUserByEmail().then((uid) => {
					this.uid = uid;
					this.authType = "email address";
				});
			}
			if (this.phoneBody) {
				this.checkUserByPhone().then((uid) => {
					this.uid = uid;
					this.authType = "phone number";
				});
			}
		},
		makeName() {
			this.name = `${this.first_name} ${this.last_name}`;
		},
	},
	watch: {
		participant: {
			immediate: true,
			handler(participant) {
				// if ( this.isValid || this.uid ) {
					this.$emit("input", participant);
				// }
			},
		},
		email(v){
			this.email = v.trim()
		},
		phone(v){
			this.phone = v.trim()
		},
		first_name() {
			this.makeName();
		},
		last_name() {
			this.makeName();
		},
	},
	inject: ["isMobile"],
};
</script>
